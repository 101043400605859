<template>
    <vue-final-modal
      v-slot="{ close }"
      v-bind="$attrs"
      classes="modal-container"
      content-class="modal-content"
      @beforeOpen="(event) => processParams(event.ref.params._rawValue)"
    >
      <!-- Begin::Header -->
      <div class="modal__header">
        <span class="modal__title">
          <slot name="title"></slot>
        </span>
      </div>
      <!-- End::Header -->
      <!-- Begin::Body -->
      <div class="modal__content">
        <!-- Begin::Details section -->
        <form @submit.prevent="saveClinic" ref="form">
          <h5 class="fw-bolder mb-6">Account type</h5>
          <div class="d-flex flex-row row mb-12">
            <div class="col-6">
              <select
                class="form-control"
                v-model="clinic.accountType"
                style="appearance: auto;"
                required
              >
                <option :value="undefined" disabled>Account type...</option>
                <option>MARKETPLACE</option>
                <option>CLINIC</option>
              </select>
            </div>
          </div>

          <h5 class="fw-bolder mb-6">Clinic Details</h5>
          <div class="d-flex flex-row row mb-12">
            <div class="d-flex flex-column col-6 mb-5">
              <label for="name">Clinic name (or Doctor's name)*</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="clinic.name"
                required
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="email">Contact email*</label>
              <input
                type="email"
                class="form-control"
                id="email"
                v-model="clinic.email"
                required
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="phone">Contact phone*</label>
              <input
                type="tel"
                class="form-control"
                id="phone"
                v-model="clinic.phone"
                required
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="operationPrice">Operation price</label>
              <div class="row">
                <div class="col-6">
                  <input
                    type="number"
                    min="0"
                    step="1"
                    class="form-control"
                    id="operationPrice"
                    v-model="clinic.operationPrice"
                  />
                </div>
                <div class="col-6">
                  <select
                    class="form-control"
                    v-model="clinic.operationCurrency"
                    style="appearance: auto;"
                  >
                    <option :value="undefined" disabled>Choose currency...</option>
                    <option>EUR</option>
                    <option>USD</option>
                    <option>CHF</option>
                  </select>
                </div>
              </div>
            </div>

            <h5 class="fw-bolder mb-6">Clinic address</h5>
            <div class="d-flex flex-column col-12 mb-5">
              <label for="street">Address line 1*</label>
              <input
                type="text"
                class="form-control"
                id="street"
                v-model="clinic.addresses[0].street"
                required
              />
            </div>
            <div class="d-flex flex-column col-12 mb-5">
              <label for="street2">Address line 2</label>
              <input
                type="text"
                class="form-control"
                id="street2"
                v-model="clinic.addresses[0].street2"
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="zip">ZIP*</label>
              <input
                type="text"
                class="form-control"
                id="zip"
                v-model="clinic.addresses[0].zip"
                required
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="city">City*</label>
              <input
                type="text"
                class="form-control"
                id="city"
                v-model="clinic.addresses[0].city"
                required
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="state">State</label>
              <input
                type="text"
                class="form-control"
                id="state"
              />
            </div>
            <div class="d-flex flex-column col-6 mb-5">
              <label for="country">Country*</label>
              <input
                type="text"
                class="form-control"
                id="country"
                v-model="clinic.addresses[0].country"
                required
              />
            </div>

            <h5 class="fw-bolder mb-6">Clinic settings</h5>
            <div class="d-flex flex-column mb-5">
              <label>
                <input type="checkbox" v-model="clinic.appearOnSite" />
                Display clinic on website
              </label>
            </div>

            <template v-if="clinic.user">
                <hr />
                <h5 class="fw-bolder mb-6">Default user for the new clinic</h5>
                <div class="d-flex flex-column col-6 mb-5">
                <label for="firstname">Firstname*</label>
                <input
                    type="text"
                    class="form-control"
                    id="firstname"
                    v-model="clinic.user.firstname"
                    required
                />
                </div>
                <div class="d-flex flex-column col-6 mb-5">
                <label for="lastname">Lastname*</label>
                <input
                    type="text"
                    class="form-control"
                    id="lastname"
                    v-model="clinic.user.lastname"
                    required
                />
                </div>
                <div class="d-flex flex-column col-6 mb-5">
                <label for="email">Email (will be used to login)*</label>
                <input
                    type="email"
                    class="form-control"
                    id="email"
                    v-model="clinic.user.email"
                    required
                />
                </div>
            </template>
          </div>
        </form>
      </div>
      <!-- End::Header -->
      <!-- Begin::Footer -->
      <div class="modal__action">
        <!-- <a class="btn btn-lg btn-primary me-2" @click="$emit('confirm', patient)"
          >Confirm</a
        > -->
        <a class="btn btn-lg btn-primary me-2" @click.prevent="onClickConfirm"
          >Confirm</a
        >
        <a class="btn btn-lg btn-secondary" @click="$emit('cancel')">Cancel</a>
      </div>
      <!-- End::Header -->
      <button class="modal__close btn btn-icon btn-link" @click="close">
        <i class="bi bi-x-lg"></i>
      </button>
    </vue-final-modal>
  </template>
  
  <script>
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import * as Yup from "yup";
  import _ from "lodash";
  
  import ApiService from "@/core/services/ApiService";
  
  // import vSelect from "vue-select";
  // import "vue-select/dist/vue-select.css";
  
  export default {
    emits: ["confirm", "cancel"],
    components: {
      // vSelect,
    },
    data() {
      return {
        clinic: {
            accountType: "",
            name: "",
            email: "",
            phone: "",
            operationPrice: 7500,
            operationCurrency: "EUR",
            addresses: [ 
                {
                    label: "DEFAULT",
                    street: "",
                    street2: "",
                    city: "",
                    zip: "",
                    state: "",
                    country: "",
                },
            ],
            appearOnSite: false,
            allowCallbackQuestion: true,

            // Default user created with the clinic (admin)
            user: {
                firstname: '',
                lastname: '',
                email: '',
            }
        },
      };
    },
    methods: {
      onClickConfirm() {
        this.$refs.form.requestSubmit();
      },
      saveClinic() {
        console.log(JSON.parse(JSON.stringify(this.clinic)));
  
        if (this.clinic.id) {
          const config = {
            responseToast: {
              text: "Clinic updated successfully."
            }
          };
  
          ApiService.update("/clinics", this.clinic.id, this.clinic, config)
            .then(({ data }) => {
              console.log(data);
              this.$emit("confirm", data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          const config = {
            responseToast: {
              text: "Clinic created successfully."
            }
          };
  
          ApiService.post("/clinics/enrollment", this.clinic, config)
            .then(({ data }) => {
              console.log(data);
              this.$emit("confirm", data);
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      processParams(existingClinic) {
        if (!_.isEmpty(existingClinic, true)) {
          // Date format
          if (existingClinic.birthdate) {
            existingClinic.birthdate = existingClinic.birthdate.substring(0, 10);
          }
  
          console.log(JSON.parse(JSON.stringify(existingClinic)));
          this.clinic = _.cloneDeep(existingClinic);
          console.log(this.clinic);
        }
      },
    },
  };
  </script>
  